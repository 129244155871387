.home-page {
    position: relative;
    padding: 0;
    background: #F1F7FF;
}

.home-page .carousel .slide {
    padding: 60px 0 0;
    text-align: center;
    height: 560px;
    overflow-y: scroll;
}

.home-page .carousel .slider {
    padding: 0px 20px 0;
    margin-bottom: 130px;
    max-height: 480px;
    overflow-y: scroll;
    position: relative;
    top: 50px;
    padding-bottom: 20px;
}

.home-page .carousel-control-prev,
.home-page .carousel-control-next {
    display: none;
}

.home-page .carousel-caption {
    position: relative;
    left: 0;
    right: 0;
}

.home-page .carousel {
    position: relative;
    height: 100vh;
    overflow-y: scroll;
}

.home-page .carousel-indicators button {
    background-color: #8799AC;
    width: 40px;
    height: 2px;
    border-radius: 4px;
    margin-left: 5px;
    margin-right: 5px;
}

.home-page .carousel-indicators .active {
    background-color: #19B3B5;
}

.home-page .carousel .slide h3 {
    color: #0F345A;
    text-align: center;
    font-family: "Satoshi-Medium";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-page .carousel .slide img {
    margin: 25px 0;
}

.home-page .carousel .slide p {
color: #0F345A;
    text-align: center;
    font-family: "Satoshi-Regular";
    font-size: 18px;
    margin: 0 49px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.home-page .carousel-indicators {
    position: relative;
    top: 42px;
}

.home-page .better-result {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 180px;
    padding: 30px;
    border-radius: 30px 30px 0px 0px;
    background: #FFF;
    box-shadow: 0px -4px 20px 0px rgba(142, 142, 142, 0.25);
}

.home-page .better-result p {
    color: #0F345A;
    text-align: center;
    padding: 0 10px;
    font-family: "Satoshi-Medium";
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-bottom: 25px;
}

.home-page .better-result a {
    color: #FFF;
    text-align: center;
    font-family: "Satoshi-Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: 50px !important;
    border-radius: 100px;
    display: block;
    background: #0F345A;
    text-decoration: none;
    line-height: 50px;
    /* 19.2px */
    text-transform: uppercase;
    width: 100% !important;
}

.home-page .carousel .slider .short-para {
    color: #0F345A;
    text-align: center;
    font-family: "Satoshi-Medium";
    font-size: 11.52px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    margin: 10px 10px 0;
}

.home-page .listing p {
    color: #0F345A;
    text-align: center;
    margin: 15px 0 0;
    font-family: "Satoshi-Medium";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    /* 14.4px */
    letter-spacing: 0.12px;
}

.home-page .carousel .slider .listing img {
    width: 60px;
    height: 60px;
    margin: 0;
}

.home-page .carousel .slider hr {
    border: 1px solid #CBD4DE;
    width: 100%;
}

.home-page .carousel .slider h4 {
    color: #0F345A;
    font-family: "Satoshi-Medium";
    font-size: 20px;
    font-style: normal;
    text-align: left;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
}

.home-page .carousel .slider ul li h5 {
    color: #0F345A;
    font-family: "Satoshi-Medium";
    font-size: 14px;
    font-style: normal;
    text-align: left;
    font-weight: 450;
    margin-bottom: 7.6px;
    line-height: 120%;
}

.home-page .carousel .slider ul li h6 {
    color: #0F345A;
    font-family: "Satoshi-Medium";
    font-size: 15.36px;
    margin-right: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
}

.home-page .carousel .slider ul li .number {
    display: flex;
    align-items: center;
}

.home-page .carousel .slider h3 {
    color: #0F345A;
    text-align: center;
    font-family: "Satoshi-Medium";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-page .carousel .slider ul {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.home-page .carousel .slider ul li img {
    margin: 0;
}

.home-page .carousel .slider ul li p {
    text-align: left;
    color: #778799;
    font-family: Nunito;
    font-size: 11.52px;
    font-style: normal;
    margin: 0;
    font-weight: 300;
    line-height: 120%;
}

.home-page .carousel .slider ul li .categ {
    margin-left: 14px;
}

.home-page .crossImg {
    position: absolute;
    top: 40px;
    right: 30px;
    z-index: 1;
}