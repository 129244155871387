/* * {
    background-color: red;
} */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,800;1,800&display=swap');

@font-face {
    font-family: 'Satoshi-Medium';
    src: url('../fonts/circular/CircularSpotifyText-Light.eot');
    src: url('../fonts/circular/CircularSpotifyText-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/circular/CircularSpotifyText-Light.woff2') format('woff2'),
        url('../fonts/circular/CircularSpotifyText-Light.woff') format('woff'),
        url('../fonts/circular/CircularSpotifyText-Light.ttf') format('truetype'),
        url('../fonts/circular/CircularSpotifyText-Light.svg#CircularSpotifyText-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi-Medium';
    src: url('../fonts/circular/CircularStd-Black.eot');
    src: url('../fonts/circular/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/circular/CircularStd-Black.woff2') format('woff2'),
        url('../fonts/circular/CircularStd-Black.woff') format('woff'),
        url('../fonts/circular/CircularStd-Black.ttf') format('truetype'),
        url('../fonts/circular/CircularStd-Black.svg#CircularStd-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi-Medium';
    src: url('../fonts/circular/CircularStd-Book.eot');
    src: url('../fonts/circular/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/circular/CircularStd-Book.woff2') format('woff2'),
        url('../fonts/circular/CircularStd-Book.woff') format('woff'),
        url('../fonts/circular/CircularStd-Book.ttf') format('truetype'),
        url('../fonts/circular/CircularStd-Book.svg#CircularStd-Book') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi-Medium';
    src: url('../fonts/circular/CircularStd-Medium.eot');
    src: url('../fonts/circular/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/circular/CircularStd-Medium.woff2') format('woff2'),
        url('../fonts/circular/CircularStd-Medium.woff') format('woff'),
        url('../fonts/circular/CircularStd-Medium.ttf') format('truetype'),
        url('../fonts/circular/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi-Medium';
    src: url('../fonts/circular/CircularStd-Bold.eot');
    src: url('../fonts/circular/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/circular/CircularStd-Bold.woff2') format('woff2'),
        url('../fonts/circular/CircularStd-Bold.woff') format('woff'),
        url('../fonts/circular/CircularStd-Bold.ttf') format('truetype'),
        url('../fonts/circular/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Satoshi Black";
    src: url("../fonts/satoshi_black/satoshi_black.woff2")format("woff2"),
        url("../fonts/satoshi_black/satoshi_black.woff")format("woff"),
        url("../fonts/satoshi_black/satoshi_black.ttf")format("truetype");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Satoshi Medium";
    src: url("../fonts/satoshi_medium/satoshi_medium.woff2")format("woff2"),
        url("../fonts/satoshi_medium/satoshi_medium.woff")format("woff"),
        url("../fonts/satoshi_medium/satoshi_medium.ttf")format("truetype");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Satoshi Variable";
    src: url("../fonts/satoshi_variable/satoshi_variable.woff2")format("woff2"),
        url("../fonts/satoshi_variable/satoshi_variable.woff")format("woff"),
        url("../fonts/satoshi_variable/satoshi_variable.ttf")format("truetype");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi-Light';
    src: url('../fonts/satoshi/Satoshi-Light.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-Light.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi-LightItalic';
    src: url('../fonts/satoshi/Satoshi-LightItalic.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-LightItalic.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Satoshi-Regular';
    src: url('../fonts/satoshi/Satoshi-Regular.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-Regular.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi-Italic';
    src: url('../fonts/satoshi/Satoshi-Italic.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-Italic.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-Italic.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Satoshi-Medium';
    src: url('../fonts/satoshi/Satoshi-Medium.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-Medium.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi-MediumItalic';
    src: url('../fonts/satoshi/Satoshi-MediumItalic.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-MediumItalic.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Satoshi-Bold';
    src: url('../fonts/satoshi/Satoshi-Bold.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-Bold.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi-BoldItalic';
    src: url('../fonts/satoshi/Satoshi-BoldItalic.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-BoldItalic.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Satoshi-Black';
    src: url('../fonts/satoshi/Satoshi-Black.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-Black.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-Black.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Satoshi-BlackItalic';
    src: url('../fonts/satoshi/Satoshi-BlackItalic.woff2') format('woff2'),
        url('../fonts/satoshi/Satoshi-BlackItalic.woff') format('woff'),
        url('../fonts/satoshi/Satoshi-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: italic;
}

.MuiContainer-root.MuiContainer-maxWidthSm {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
}

/* sehat */
.sehat-scan {
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
}

/* profile */

.profile {
    /* padding-left: 16px;
    padding-right: 16px; */
    margin-bottom: 110px;
}

.profile .scanmark {
    width: 100%;
    height: 100%;
    margin-top: 50px;
}

.profile .VideoAndStatsWrapper {
    position: relative;
}

.profile .update-profile-btn {
    border-radius: 100px;
    border: 1px solid #0F345A;
    height: 50px;
    padding: 8px 0;
    text-align: center;
    background-color: white;
    color: #0F345A;
    width: 300px;
    margin: auto;
    display: block;
}

.profile .update-profile-btn span {
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.2px;
    text-transform: uppercase;
}

.profile .avail {
    /* position: absolute; */
    justify-content: space-between;
    /* top: 67px; */
    /* left: 0; */
    display: flex;
    /* width: 100%; */
    /* height: 50px; */
    padding: 6px 10px;
    border-radius: 0;
    /* background: rgba(15, 52, 90, 0.90); */
    z-index: 1;
}

.profile .avail p {
    color: #FFF;
    font-family: "Satoshi Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    line-height: 27px;
}

.profile .avail p img {
    margin-right: 10px;
}



.profile .avail .timer {
    color: #0F345A;
    text-align: center;
    font-family: 'Satoshi-Bold';
    display: block;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 7px;
    /* padding-top: 10px; */
}

.profile .avail .rem span {
    font-family: 'Satoshi-Medium';
    font-size: 12px;
    font-weight: 450;
    color: #19B3B5;
    line-height: 14.51px;
    text-align: center;
}

.profile .better-results {
    color: #0F345A;
    text-align: center;
    margin-top: 10px;
    font-family: 'Satoshi-Medium';
    font-size: 16px;
    font-style: normal;
    margin-bottom: 20px;
    font-weight: 500;
    line-height: normal;
}

.profile .start-scan,
.fullWidthButton .start-scan {
    border-radius: 100px;
    border: none;
    background: #0F345A;
    height: 50px;
    margin-top: 20px;
    width: 100%;
    position: relative;
}

.profile .start-scan span,
.fullWidthButton .start-scan span {
    color: #FFF;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    position: relative;
}

.profile .start-scan .number,
.fullWidthButton .start-scan .number {
    padding: 7px 26px;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.10);
    color: #FFF;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-left: 10px;
}

.profile .learn_more {
    color: #0F345A;
    text-align: center;
    font-family: "Satoshi Medium";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-top: 0px;
    line-height: normal;
    width: 100%;
    display: block;
}

.profile .learn_more a {
    color: #0645AD;
    text-align: center;
    font-family: "Satoshi Medium";
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
}

.profile .sehat-scan .infoseh {
    min-width: auto;
}

.profile .start-camera {
    border-radius: 8px;
    background: #29BCC1;
    width: 100%;
    height: 50px;
    padding: 11px 33px;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.2px;
    text-transform: uppercase;
}

/* updateProfile */

.updateprofile .MuiBox-root {
    padding: 28px 10px 22px;
    height: 97%;
    width: 94%;
}

.updateprofile {
    position: relative;
}

.updateprofile .close {
    position: absolute;
    top: 10px;
    right: 10px;
}

.updateprofile .form-select {
    color: #9B9B9B;
    font-family: 'Satoshi-Medium';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 107.5%;
}

.updateprofile .form-select:first-child {
    margin-right: 10px;
}

.updateprofile .form-select:last-child {
    margin-left: 10px;
}

.updateprofile .instructionModals h4 {
    color: #0F345A;
    text-align: center;
    font-family: Satoshi-Medium;
    font-size: 22px;
    margin-bottom: 14px;
    font-style: normal;
    margin-bottom: 25px;
    font-weight: 450;
    line-height: normal;
}

.genderTabsOption label:first-child {
    margin-right: 10px;
}

.genderTabsOption label:last-child {
    margin-left: 10px;
}

.updateProfile .label {
    color: #535151;
    font-family: 'Satoshi-Medium';
    font-size: 14px;
    margin-bottom: 14px;
    font-style: normal;
    width: 100%;
    font-weight: 450;
    line-height: normal;
}

.genderTabsOption label::before {
    display: none !important;
}

.genderTabsOption label {
    background: #FFFFFF;
    box-shadow: 0px -0.944px 3.778px 0px rgba(0, 0, 0, 0.10);
    border-radius: 5px;
    padding: 0;
    width: 90px;
    margin: 0;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Satoshi-Medium';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
}

.MuiButtonBase-root.MuiRadio-root {
    display: none;
}

.genderTabsOption .selected {
    border-radius: 5px;
    border: 1px solid #2A87FE;
    background: #FFF;
    box-shadow: 0px -1.889px 3.778px 0px rgba(198, 198, 198, 0.10) inset, 0px 5.667px 9.444px 0px rgba(0, 0, 0, 0.12) inset;
}

.updateProfile .genderTabsOption {
    display: flex;
    flex-direction: row;
}

.updateprofile .gender .MuiFormControl-root:last-child {
    padding-left: 0;
}

.btn-icons-gender {
    display: block;
    margin: auto;
    margin-bottom: 5px;
}

.genderTabsOption .MuiFormControlLabel-labelPlacementEnd .MuiTypography-body1 {
    color: #313131;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.genderTabsOption .selected .MuiTypography-body1 {
    color: #2A87FE;
}

.genderTabsOption .selected .MuiTypography-body1 img {
    filter: brightness(0) saturate(100%) invert(42%) sepia(91%) saturate(2312%) hue-rotate(198deg) brightness(101%) contrast(99%);
}

.updateProfile .schedule-appointment {
    border-radius: 100px;
    background: #0F345A;
    color: #FFF;
    text-align: center;
    font-family: "Satoshi-Medium";
    font-size: 16px;
    font-style: normal;
    width: 89%;
    height: 50px;
    border: none;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.8px;
    position: absolute;
    bottom: 20px;
}

/* result */
.tab-nav .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tab-nav .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer::-webkit-scrollbar {
    display: none;
}

.scanTabs .nav-link {
    color: #0F345A;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    position: relative;
    font-weight: 300;
    justify-content: center;
    padding-top: 17px;
    padding-bottom: 17px;
    line-height: normal;
    font-family: "Satoshi-Regular";
}

.scanTabs .nav-link.actived {
    color: #0F345A;
    position: relative;
    background-color: transparent;
    font-weight: 500;
    font-family: "Satoshi-Medium";
}

.scanTabs .nav-link.actived:before {
    position: absolute;
    left: 0;
    content: '';
    background-color: #0F345A;
    bottom: 0;
    width: 100%;
    height: 4px;
}

.scanTabs .avail {
    position: fixed;
    justify-content: space-between;
    bottom: 77px;
    align-items: center;
    left: 0;
    display: flex;
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    padding: 5px 10px 21px 10px;
    background: #0F345A;
}

.scanTabs .avail p {
    color: #FFF;
    font-family: "Satoshi Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    line-height: 27px;
}

.scanTabs .avail p img {
    margin-right: 10px;
}

.scanTabs .avail .rem {
    background-color: #D0F9FB;
    border-radius: 8px;
    width: 87px;
    height: 40px;
    line-height: normal;
    text-align: center;
}

.scanTabs .avail .timer {
    color: #19B3B5;
    text-align: center;
    font-family: 'Satoshi-Medium';
    display: block;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.scanTabs .avail .rem span {
    font-family: 'Satoshi-Medium';
    font-size: 12px;
    font-weight: 450;
    color: #19B3B5;
    line-height: 14.51px;
    text-align: center;
    position: relative;
    top: -4px;
}

.tab-nav .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer .Mui-selected:before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 4px;
    background: #29BCC1;
}

.tab-nav .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer .Mui-selected {
    color: #29BCC1;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    font-family: "Satoshi Variable";
}

.tab-nav .MuiTabs-indicator {
    display: none;
}

.scan-result .tab-bg {
    background: #F1F5FC;
}

.scanTabs .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 0;
    background-color: white;
    z-index: 111;
}

.scanTabs .boxed {
    border-radius: 20px;
    text-align: center;
    margin-bottom: 30px;
    background: rgba(135, 153, 172, 0.10);
    padding: 10px 16px;
}

.scanTabs .boxed p {
    color: #0F345A;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 12px;
    font-style: normal;
    margin-bottom: 10px;
    font-weight: 300;
    line-height: normal;
}

.scanTabs .boxed a {
    color: #19B3B5;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.scanTabs h5 {
    color: #0F345A;
    font-family: 'Satoshi-Bold';
    font-size: 18px;
    font-style: normal;
    margin-left: 10px;
    margin-bottom: 0;
    font-weight: 500;
    line-height: normal;
}

.scanTabs .box-bg {
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    padding: 16px 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 20px 0px rgba(15, 52, 90, 0.10);
}

.scanTabs .box-bg span {
    color: #0F345A;
    font-family: 'Satoshi-Medium';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    position: relative;
    line-height: normal;
}

.scanTabs .box-bg span span.title {
    text-transform: capitalize;
}

.scanTabs .box-bg p {
    color: #0F345A;
    text-align: right;
    font-family: 'Satoshi-Medium';
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.scanTabs .box-bg p span {
    color: #8799AC;
    font-size: 20px;
    font-weight: 450;
}

.scanTabs .box-bg .risk {
    color: #F06386;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

/* .scanTabs .MuiGrid-spacing-xs-3 {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
} */

.doctor-button {
    border-radius: 15px 15px 0px 0px;
    background: #FFF;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    padding: 20px;
    width: 100%;
    box-shadow: 0px -4px 20px 0px rgba(15, 52, 90, 0.10);
}

.doctor-button .btn {
    text-align: center;
    font-family: 'Satoshi-Medium';
    width: 160px;
    font-size: 16px;
    border-radius: 8px;
    font-style: normal;
    height: 50px;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.doctor-button .call {
    background: #29BCC1;
    color: #FFF;
}

.doctor-button .scan {
    border-radius: 100px;
    color: #0F345A;
    border: 1.5px solid #0F345A;
}

.scan-result.MuiBox-root.css-8atqhb {
    height: 100vh;
    overflow-y: scroll;
}

/* scanResult_modal */

.scanResult {
    min-width: auto !important;
}

.scanResult_modal {
    position: relative;
}

.scanResult_modal .scanResult-bg {
    background: #F5F5F5;
    border-radius: 15px 15px 0px 0px;
    text-align: center;
    padding: 30px 43px;
}

.scanResult_modal .close {
    position: absolute;
    top: 10px;
    right: 10px;
}

.scanResult_modal .scanResult-bg h5 {
    color: #0F345A;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 20px;
    font-style: normal;
    margin: 0 0 13px;
    font-weight: 450;
    line-height: normal;
}

.scanResult_modal .scanResult-bg h4 {
    font-family: 'Satoshi-Medium';
    font-size: 36px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    color: #0F345A;
    text-align: center;
}

.scanResult_modal .scanResult-bg h4 span {
    color: #8799AC;
    font-family: 'Satoshi-Medium';
    font-size: 32px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.scanResult_modal .scanResult-bg h6 {
    color: #F06386;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 12px;
    margin: 0 0 20px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    line-height: normal;
}

.scanResult_modal .scanResult-bg button {
    border-radius: 100px;
    background: #0F345A;
    color: #FFF;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 42px;
    width: 100%;
    position: relative;
    border: none;
}

.scanResult_modal .scanResult-bg button img {
    position: absolute;
    border-radius: 0px 5px 5px 0px;
    background: #148F91;
    padding: 13.5px 15px;
    top: 0;
    right: 0;
    bottom: 0;
}

.scanResult_modal .scanResultPara {
    padding: 20px 19px;
    overflow-y: scroll;
    min-height: 256px;
    max-height: 400px;
    overflow-x: hidden;
}

.scanResult_modal .scanResultPara p * {
    color: #0F345A !important;
    font-family: 'Satoshi-Medium' !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: normal !important;
}

.scanTabs .navbar .nav {
    flex-wrap: inherit;
    width: 985px;
    overflow-x: scroll;
}

.scanTabs .navbar {
    display: block;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scanTabs .navbar::-webkit-scrollbar {
    display: none;
}

.scanTabs .bg-col {
    background: #F1F5FC;
    padding: 20px;
}

button.start-scan.start_camera {
    position: fixed;
    bottom: 30px;
    width: 92%;
}

.video-wrap {
    padding-left: 16px;
    padding-right: 16px;
}

.VideoWrapper video {
    margin: auto;
    border-radius: 218.414px;
    border: 3.64px solid #19B3B5;
    width: 251px;
    height: 310px;
    object-fit: cover;
}

.VideoWrapper.whenCameraOpen {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
}

.mobile-top-bar {
    background: white;
}

.mobile-top-bar-icon svg {
    color: black;
    font-size: 23px;
}

.mobile-top-bar div:nth-child(2) {
    order: 3;
}

.mobile-top-bar div:last-child {
    order: 2;
    position: relative;
}

.mobile-top-bar div:last-child .mobile-top-bar-icon:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background-image: url(https://ms-images.s3.ap-southeast-1.amazonaws.com/images/favicon.ico);
    width: 44px;
    height: 44px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
}

.start-camera-button {
    border-radius: 100px;
    background: #0F345A;
    height: 50px;
    width: 300px;
    color: #FFF;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
}

.start-measurement-button {
    border-radius: 8px;
    background: #0F345A;
    color: #FFF;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
}

.doctor-button a.btn.call {
    background: #0F345A !important;
    color: white !important;
    margin-right: 10px;
    border-radius: 100px;
}

.MuiModal-root.updateprofile.css-79ws1d-MuiModal-root {
    background: rgba(15, 52, 90, 0.20);
    backdrop-filter: blur(10px);
}

.mobile-top-bar div:last-child span svg {
    display: none;
}

div.single_ite_main {
    display: inline-flex;
    max-width: 100%;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    width: 100%;
}

.swiper-container {
    box-shadow: inset 0 4.739px 9.479px 0 #0000001a;
    display: inline-flex;
    max-width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    width: 100%;
    padding: 6px 0;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    padding-bottom: 15px;
    height: 48px;
}

.swiper-container.swiper-container-ios::-webkit-scrollbar {
    display: none;
}

.swiper-container:after {
    border-bottom: 10px solid #19B3B5;
    border-left: 7px solid #0000;
    border-right: 5px solid #0000;
    bottom: 0;
    content: "";
    left: 49%;
    position: absolute;
}

.item.single_ite {
    text-align: center;
    color: #97A9BD;
    font-family: 'Satoshi-Regular';
    margin-top: 5px;
}

.swiper-slide.swiper-slide-active.item.single_ite {
    color: #19B3B5;
    font-weight: 700;
}

.ant-select-dropdown {
    z-index: 99999;
}

label.ant-radio-button-wrapper.activeGender {
    border-radius: 5px;
    border: 1px solid #19B3B5 !important;
    background: #FFF;
    color: #19B3B5 !important;
    font-family: "Satoshi-Medium" !important;
    box-shadow: 0px -1.889px 3.778px 0px rgba(198, 198, 198, 0.10) inset, 0px 5.667px 9.444px 0px rgba(0, 0, 0, 0.12) inset;
}

label.ant-radio-button-wrapper.activeGender img {
    filter: invert(66%) sepia(51%) saturate(499%) hue-rotate(129deg) brightness(96%) contrast(89%);
}

.swiper-slide.item.single_ite.active_Item {
    color: #19B3B5;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

button.schedule-appointment.statenotchanged {
    filter: grayscale(100%);
}

.loader_wrapper {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 999;
    left: 0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader_wrapper img {
    height: 100px;
}

.box-bg.last_vitals {
    margin-bottom: 100px;
}

.hk_share {
    z-index: 9999;
    position: relative;
}

.closeIcon {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 999;
}

.hk_share .makeStyles-copyContainer-5 {
    display: none;
}

.main_single_wrapper:last-child {
    margin-bottom: 110px;
}

.subscribeModalMains {
    width: 320px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
}

.subscribeModal {
    padding-bottom: 20px;
    padding-top: 20px;
}

.subscribeModal h2 {
    color: #0F345A;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 24px;
    font-style: normal;
    font-weight: 450;
    line-height: 28px;
    /* 116.667% */
}

.subscribeModal p {
    color: #0F345A;
    text-align: center;
    font-family: 'Satoshi-Regular';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 30px;
}

a.btnPlan {
    border-radius: 100px;
    background: #0F345A;
    display: flex;
    width: 100%;
    height: 50px;
    padding: 11px 33px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    border: 0px;
    text-decoration: none;
}

.subscribeModalMains .ant-modal-footer {
    display: none;
}

.ant-modal.subscribeModalMains .ant-modal-content {
    border-radius: 15px;
}

.fullWidthButton button.start-scan {
    width: 90%;
}

.fullWidthButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;
}

.fullWidthButton a.start-scan {
    text-decoration: none;
    text-align: center;
    line-height: 49px;
}

.subscribeModalMains.pleaseSubs .ant-modal-content button.ant-modal-close {
    display: none;
}

form#basic {
    max-height: 480px;
    overflow-y: auto;
    overflow-x: hidden;
}

/* form#basic::-webkit-scrollbar {
    display: none;
}

form#basic {
    -ms-overflow-style: none;
    scrollbar-width: none;
} */

.dateOfBirth.customer_date_box.bmi_row .ant-form-item.c_select.customer_date {
    width: 296px;
}

.dateOfBirth.customer_date_box :where(.css-dev-only-do-not-override-m4timi).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #19B3B5 !important;
}

:where(.css-dev-only-do-not-override-m4timi).ant-checkbox-checked .ant-checkbox-inner {
    background-color: #19B3B5 !important;
    border-color: #19B3B5 !important;
}

:where(.css-m4timi).ant-checkbox-checked .ant-checkbox-inner {
    background-color: #19B3B5 !important;
    border-color: #19B3B5 !important;
}

.dateOfBirth.customer_date_box :where(.css-dev-only-do-not-override-m4timi).ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
}

.dateOfBirth.customer_date_box :where(.css-dev-only-do-not-override-m4timi).ant-checkbox+span {
    color: #97A9BD;
    font-family: 'Satoshi-Regular';
    font-weight: 500;
}

.diabet {
    color: #535151;
    font-family: 'Satoshi-Medium';
    font-weight: 450;
}

.radio-diabet {
    display: flex;
    justify-content: space-between;
    padding: 0 2px;
}

.radio-diabet :where(.css-dev-only-do-not-override-m4timi).ant-radio-wrapper span.ant-radio+* {
    color: #19B3B5;
    font-family: 'Satoshi-Regular';
}

.radio-diabet :where(.css-dev-only-do-not-override-m4timi).ant-radio-wrapper .ant-radio-inner {
    width: 20px;
    height: 20px;
}

.radio-diabet :where(.css-dev-only-do-not-override-m4timi).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    box-shadow: 0px 0px 0px 1.5px #19B3B5 !important;
    background-color: #19B3B5 !important;
    border: 4px solid #fff;
}

:where(.css-m4timi).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #19B3B5 !important;
    box-shadow: 0px 0px 0px 1.5px #19B3B5 !important;
    background-color: #19B3B5 !important;
}

.radio-diabet :where(.css-dev-only-do-not-override-m4timi).ant-radio-wrapper .ant-radio-inner::after {
    background-color: transparent;
}

.package-detail .package-scan {
    padding-top: 15px;
    padding-left: 16px;
    box-shadow: 0px 3.995px 7.99px 0px rgba(0, 0, 0, 0.05);
    padding-right: 16px;
    padding-bottom: 15px !important;
    background-color: white;
}

section.package-detail {
    background: #E6EBF4;
    height: 100vh;
}

.package-detail .package-scan h6 {
    margin: 0;
    margin-left: 16px;
    font-family: 'Satoshi Medium';
    color: #0F345A;
}

.package-detail .video-player {
    position: relative;
    padding: 25px 20px;
}

.package-detail .video-player ul {
    padding-left: 0;
}

.package-detail .video-player ul li {
    display: flex;
    align-items: start;
    padding-bottom: 54px;
    position: relative;
}

.package-detail .video-player ul li:before {
    position: absolute;
    top: 45px;
    left: 10px;
    width: 1px;
    height: 45px;
    content: '';
    border-left: 1px dotted rgba(49, 49, 49, 0.50);
}

.package-detail .video-player ul li:last-child:before {
    content: none;
}

.package-detail .video-player .count {
    background-color: #19B3B5;
    width: 24px;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    display: flex;
    border-radius: 50%;
    height: 24px;
    color: #FFF;
    font-family: 'Satoshi-Medium';
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.package-detail .video-player ul li p {
    margin-left: 12px;
    width: 89%;
    font-family: "Satoshi-Regular";
    color: #0F345A;
}

.package-detail .video-player .continue {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 15px 15px 0px 0px;
    padding: 20px;
    background: #FFF;
    box-shadow: 0px -4px 20px 0px rgba(142, 142, 142, 0.25);
}

.package-detail .video-player .continue a {
    border-radius: 100px;
    display: block;
    height: 50px;
    width: 100%;
    background: #0F345A;
    color: #FFF;
    text-align: center;
    font-family: "Satoshi-Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.14px;
    text-transform: uppercase;
    box-shadow: 0px 4px 15px 0px rgba(25, 179, 181, 0.30);
}

.package-detail .video-player .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

.package-detail .video-player .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.package-detail .video-player .video-play {
    position: relative;
    margin-bottom: 20px;
}

.package-detail .video-player .video-play img {
    width: 100%;
}

.package-detail .video-player .video-play p {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
}

.package-detail .video-player .video-play p img {
    width: 71.758px;
    height: 71.758px;
}

.info_icon {
    display: inline-block;
    position: relative;
    left: 5px;
    top: -3px;
}

h6.tag__s {
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

.ant-form-item.c_select.customer_date .ant-select {
    width: 97%;
    height: 43px;
}

.scrollspy-example .main_single_wrapper {
    margin-bottom: 40px;
}

.scrollspy-example .main_single_wrapper:last-child {
    margin-bottom: 120px;
}

.wrapper_btn {
    border-radius: 15px 15px 0px 0px;
    background: #FFF;
    display: flex;
    justify-content: space-evenly;
    padding: 20px;
    width: 100%;
    left: 0;
}

.wrapper_btn button.start-scan {
    margin-top: 0;
}

.remainder_all_vitals {
    border-radius: 20px;
    position: fixed;
    top: 73px;
    width: 90%;
    height: 205px;
    padding: 15px 10px;
    margin: auto;
    left: 22px;
    right: auto;
    text-align: center;
    z-index: 9;
    background-color: rgb(1 1 114 / 74%);
    background-blend-mode: darken;
}

.remainder_all_vitals .icon_lock {
    width: 40px;
    height: 40px;
    margin: auto;
    background: white;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;
}

.remainder_all_vitals p {
    color: white;
    font-family: 'Satoshi-Medium';
    font-size: 18px;
    font-style: normal;
    margin-left: 10px;
    margin-bottom: 0;
    font-weight: 300;
    line-height: normal;
}

.remainder_all_vitals button.downloadNow {
    border-radius: 8px;
    background: #FFF;
    width: 280px;
    height: 42px;
    color: #19B3B5;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    text-transform: uppercase;
    margin-top: 10px;
}

.bg-col.hk_blur {
    background-color: #00000000 00;
    background: #F1F5FC;
    background-blend-mode: darken;
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.inside_box .doctor-button {
    background: transparent !important;
    position: relative !important;
    bottom: auto;
    box-shadow: none;
    top: 10px;
    padding: 0;
    margin: 0;
}

.hk_form_up.hk_mob_update_profile.newDesignFonts p {
    color: #0F345A;
    font-family: 'Satoshi-Medium';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

p.diabet {
    color: #97A9BD !important;
    font-family: 'Satoshi-Regular' !important;
}

.profile .learn_more a {
    color: #0645AD !important;
    text-align: center;
    font-family: 'Satoshi-Regular';
    font-size: 16px;
    text-decoration: underline !important;
}

.d-flex.hk_resultLoader g {
    fill: #0F345A;
    text-align: center;
    font-family: 'Satoshi-Medium';
    font-size: 20px;
}

.avail.hk_outside p {
    font-size: 14px !important;
    width: 100% !important;
    display: block;
}

.avail.hk_outside {
    border-radius: 10px;
    background: rgba(15, 52, 90, 0.90);
    width: 90%;
    height: 40px;
    margin: auto;
    padding: 6px 20px;
    text-align: center;
}

.logo_only img {
    position: relative;
    left: 25px;
}

.profile .wrapper_btn button.start-scan {
    width: 300px;
    margin: auto;
    display: block;
}

.fullWidthButton.whenTryJourneyIsAvail button.btn.scan.ticker {
    width: 100%;
    background: #0F345A;
    color: white;
    position: relative;
}

.fullWidthButton.whenTryJourneyIsAvail button.btn.scan span.ticker {
    background: #1d5d94;
    position: absolute;
    right: 76px;
    width: 100px;
    height: 34px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullWidthButton.whenTryJourneyIsAvail button.btn.scan {
    width: 300px;
    border-radius: 82px;
    background: #0F345A;
    color: white;
}

.fullWidthButton.whenTryJourneyIsAvail button.btn.scan span.text_button {
    position: relative;
    left: -50px;
}

.profile .wrapper_btn button.start-scan span.ticker {
    background: #1d5d94;
    position: absolute;
    right: 11px;
    width: 100px;
    height: 34px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 8px;
}

.profile .wrapper_btn button.start-scan span.btn_text_wrapper {
    position: relative;
    left: -50px;
}

.dateOfBirth.customer_date_box span {
    color: #97A9BD !important;
}